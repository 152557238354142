<div *ngIf="gridData" class="card-component" [@animationState]="animate ? 'active' : 'inactive'">
    <kendo-grid #table
                [data]="gridData"
                [pageSize]="take"
                [skip]="skip"
                [pageable]="pageable"
                [height]="searchModel.height"
                [kendoGridSelectBy]="searchModel.dataKey"
                [(selectedKeys)]="selectedKeys"
                [selectable]="selectableSettings"
                [sortable]="sortable"
                [sort]="sort"
                [groupable]="groupable"
                [group]="group"
                [reorderable]="true"
                [resizable]="true"
                [columnMenu]="false"
                [rowClass]="rowClass"
                (cellClick)="onCellClick($event)"
                (dblclick)="onDblClick($event)"
                (dataStateChange)="onDataStateChange($event)">
        <ng-template kendoGridToolbarTemplate>
            <kendo-textbox [placeholder]="searchLabel" [style.width.em]="20"
                           [clearButton]="true" (afterValueChanged)="onQuickSearchValueChange($event)">
            </kendo-textbox>
            <div *ngIf="selectedKeys.length>0">({{ selectedKeys.length }}/{{ gridData.total }})</div>
            <kendo-grid-spacer></kendo-grid-spacer>

            <ng-content></ng-content>
            <button kendoGridExcelCommand *ngIf="toExcelButtonVisible" themeColor="warning" [disabled]="!canExport"> Excel </button>
            <button kendoGridPDFCommand *ngIf="toPdfButtonVisible" themeColor="warning" [disabled]="!canExport">Pdf</button>

            <button *ngIf="insertButtonVisible" kendoButton (click)="insert()" themeColor="success"> {{ insertLabel }} </button>
            <button *ngIf="deleteButtonVisible" kendoButton (click)="delete()" themeColor="error" [disabled]="!canSelect"> {{ deleteLabel }} </button>

            <kendo-button (click)="table.autoFitColumns()" themeColor="warning" icon="arrows-resizing" [title]="autofitColumnsLabel"></kendo-button>
            <kendo-grid-column-chooser></kendo-grid-column-chooser>
        </ng-template>
        <kendo-grid-checkbox-column *ngIf="checkboxColumnVisible" [width]="45"
                                    [headerClass]="{ 'text-center': true }"
                                    [class]="{ 'text-center': true }"
                                    [resizable]="false"
                                    [columnMenu]="false"
                                    [showSelectAll]="showSelectAll">
        </kendo-grid-checkbox-column>
        <kendo-grid-column *ngFor="let col of searchModel.columns"
                           [field]="col.field"
                           [title]="col.title"
                           [format]="col.format"
                           [hidden]="col.hidden"
                           [media]="col.media"
                           [filter]="col.filterType"
                           [style]="col.style"
                           [headerStyle]="col.headerStyle">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <ng-container [ngSwitch]="col.fieldType">
                    <span *ngSwitchCase="'checkbox'" class="checkbox-column">
                        <span class="k-icon k-icon-lg" [ngClass]="{'k-i-minus-outline k-color-error': !dataItem[col.field], 'k-i-check-circle k-color-success': dataItem[col.field]}"></span>
                        &nbsp;<span style="font-size:14px;">{{ dataItem[col.field] === true ? yesLabel : noLabel}}</span>
                    </span>
                    <div *ngSwitchCase="'row-button'" class="row-button-column">
                        <button kendoButton size="small"
                                [ngStyle]="{'font-size': '12px'}"
                                [themeColor]="col.theme"
                                (click)="onColumnButtonClickEvent($event, col.field, dataItem)">
                            {{col.title}}
                        </button>
                    </div>
                    <span *ngSwitchCase="'link'" class="routerLink-column">
                        <a [routerLink]="[col.link, dataItem[searchModel.dataKey], parentId]" [attr.target]="col.target">
                            {{dataItem[col.field]}}
                        </a>
                    </span>
                    <div *ngSwitchCase="'date'" class="date-column">
                        {{dataItem[col.field] | kendoDate}}
                    </div>
                    <div *ngSwitchCase="'numeric'" class="numeric-column">
                        {{dataItem[col.field] | kendoNumber: '#,##0'}}
                    </div>
                    <div *ngSwitchCase="'decimal'" class="decimal-column" [ngClass]="{'negative': dataItem[col.field] < 0, 'great-than-zero': dataItem[col.field] > 0}">
                        {{dataItem[col.field] | kendoNumber: '#,##0.00'}}
                        <ng-template *ngIf="draw" kendoGridFooterTemplate>
                            {{draw[col.field] | kendoNumber: '#,##0.00'}}
                        </ng-template>
                    </div>
                    <div *ngSwitchDefault>
                        {{dataItem[col.field]}}
                    </div>
                </ng-container>
            </ng-template>
            <ng-template kendoGridGroupHeaderTemplate let-value="value"> {{value}} </ng-template>
        </kendo-grid-column>
        <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
        <kendo-grid-pdf [fileName]="pdfFileName"
                        [allPages]="true"
                        paperSize="A4"
                        margin="1cm"
                        [scale]="0.6"
                        [repeatHeaders]="true">
            <ng-template kendoGridPDFTemplate
                         let-pageNum="pageNum"
                         let-totalPages="totalPages">
                <div class="page-template">
                    <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
                </div>
            </ng-template>
        </kendo-grid-pdf>
    </kendo-grid>
</div>
