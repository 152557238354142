import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormlyFieldInput } from './input.type';
import { FormlyFormFieldModule } from '../form-field/public_api';

@NgModule({
    declarations: [FormlyFieldInput],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormlyFormFieldModule,
        InputsModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'input',
                    component: FormlyFieldInput,
                    wrappers: ['form-field'],
                },
                { name: 'string', extends: 'input' },
                {
                    name: 'number',
                    extends: 'input',
                    defaultOptions: {
                        props: {
                            type: 'number',
                        },
                    },
                },
                {
                    name: 'integer',
                    extends: 'input',
                    defaultOptions: {
                        props: {
                            type: 'number',
                        },
                    },
                },
            ],
        }),
    ],
})
export class FormlyInputModule { }
