import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormlyFieldRadio } from './radio.type';
import { FormlyFormFieldModule } from '../form-field/public_api';

@NgModule({
    declarations: [FormlyFieldRadio],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        LabelModule,
        InputsModule,
        FormlyFormFieldModule,
        FormlySelectModule,
        FormlyModule.forChild({
            types: [
                {
                    name: 'radio',
                    component: FormlyFieldRadio,
                    wrappers: ['form-field'],
                },
            ],
        }),
    ],
})
export class FormlyRadioModule { }
