//PrimeNgConfig
export const enPrimeNgMessages = {
    "userName": "Name or email",
    "password": "Password",
    "rememberMe": "Remember me",
    "common.select": "Select...",
    "common.yes": "Yes",
    "common.no": "No",
    "common.insert": "Insert",
    "common.delete": "Delete",
    "common.search": "Search",
    "common.download": "Download",
    "common.autofit": "Autofit columns",
    "common.settings": "Settings",
    "common.cancel": "Cancel",
    "common.confirmation": "Confirmation",
    "common.save": "Save",
    "common.saveAndExit": "Save & exit",
    "common.about": "About",
    "common.warning": "Warning",
    "common.checkAll": "Check all",
    "common.itemsSelected": "items selected.",
    "common.filterExist": "Filter is active.",

    "message.successfullyCompleted": "Successfully completed.",//
    "message.deletionCompleted": "Deletion completed.",//
    "message.insertionCompleted": "Insertion completed.",//
    "message.deleteSelection": "You have selected {0} records to delete!",
    "message.canDeactivate": "You have unsaved changes. Would you like to save?",//
    "message.successfullySaved": "Successfully saved data.",//
    "message.failedToDelete": "Failed attempt to delete.",
};

// Custom Messages
export const enCustomMessages = {
    "common.select": "Select...",
    "common.yes": "Yes",
    "common.no": "No",
    "common.insert": "Insert",
    "common.delete": "Delete",
    "common.search": "Search",
    "common.autofit": "Autofit columns",
    "common.cancel": "Cancel",
    "common.save": "Save",
    "common.saveAndExit": "Save & exit",
    "common.about": "About",
    "common.warning": "Warning",
    "common.confirmation": "Confirmation",
    "common.areYouSure": "Are you sure?",

    "message.successfullyCompleted": "Successfully completed.",
    "message.deletionCompleted": "Deletion completed.",
    "message.insertionCompleted": "Insertion completed.",
    "message.canDeactivate": "You have unsaved changes. Would you like to save?",
    "message.successfullySaved": "Successfully saved data.",

    // common
    "common.companyName": "Serefidis & Partners",
    "common.companySubName": "Tax financial services",
    "common.login": "Login",
    "common.signIn": "Sign in",
    "common.logout": "Logout",
    "common.rememberMe": "Remember me",
    "common.office": "Office",
    "common.home": "Home",
    "common.greek": "Greek",
    "common.english": "English",
    'common.noRecords': 'No records available.',
    "common.importResources": "Import languages",
    "common.create": "Create",
    "common.modify": "Modify",
    "common.refresh": "Refresh",
    "common.clearAll": "Clear all",
    "common.view": "View",
    "common.from": "From",
    "common.to": "To",
    "common.type": "Type",
    "common.kind": "Kind",
    "common.searchUser": "Search user",
    "common.searchPermission": "Search permission",
    "common.permissions": "Permissions",
    "common.autoInsert": "Auto insert",
    "common.retrieve": "Retrieve",
    "common.submited": "Submited",
    "common.trader": "Trader",
    "common.employers": "Employers",
    "common.calc": "Calc",
    "common.inform": "Inform",
    "common.check": "Check",
    "common.period": "Period",
    "common.register": "Register",
    "common.collapse": "Collapse",
    "common.expand": "Expand",
    "common.actions": "Actions",
    "common.payroll": "Payroll",
    "common.saveState": "Save state",
    "common.removeState": "Remove state",
    "common.saveStateFilter": "Save filter state",
    "common.removeStateFilter": "Remove filter state",
    "common.degree": "level",
    "common.nonstop": "Nonstop",
    "common.split": "Split",
    "common.overtime": "Overtime",
    "common.total": "Total",
    "common.min": "Min",
    "common.max": "Max",
    "common.average": "Average",
    "common.grandTotal": "GrandTotal",
    "common.identityPayment": "Identity payment",
    "common.hireDate": "Hire date",
    "common.aade": "AADE",
    "common.ika": "IKA",
    "common.oaee": "OAEE",
    "common.efka": "EFKA",
    "common.keao": "KEAO",
    "common.aggregates": "Total",
    //grid
    "grid.footerSelected": "You have selected {0} items.",
    //message
    "message.noAction": "No action.",
    "message.loggerInfo": "Please check the file log.",
    "message.internalServerError": "Internal Server Error.",
    "message.invalidUrl": "Invalid url address.",
    "message.deleteConfirmation": "Delete confirmation",
    "message.inactiveMultipleDeletes": "Multiple deletion of records is disabled.",
    "message.hasSelections": "Select one record at least.",
    "message.transferCompleted": "Successfully completed.",
    "message.modifyCompleted": "Modification completed.",
    "message.sendScheduleCompleted": "Schedule program sending successfully.",
    "message.unsavedChanges": "You have unsaved changes.",
    "message.successfullySubmited": "Successfully submited.",
    "message.sendEmailValidation": "We have sent you an email about confirming your account.",
    "message.sendEmailPasswordRecovery": "We have sent you an email about password recovery.",
    "message.sendEmailSuccess": "Successfully sent email.",
    "message.changeEmailSuccess": "Successfully email changed.",
    "message.testEmailSuccess": "Successfully test email sending.",
    "message.sendInfoMessage": "Successfully message sending.",
    "message.changePasswordSuccess": "Successfully password changed.",
    "message.invalidFormValidation": "Invalid form validation",
    "message.invalidVat": "Invalid Vat",
    "message.contractChange": "<div>A contract change is being attempted for the following employees: <br/> <br/> {0} <br/> <br/> Do you want to inform?",
    "message.invalidYear": "Dates have different year",
    "message.invalidMonth": "Month 'From' must be less or equal from month 'To'",
    "message.traderNotEmpty": "Trader field must not empty",
    "message.periodNotEmpty": "Period field must not empty",
    "message.checkConnection": "Check was completed successfully.",
    "message.processCompleted": "Process completed.",

    //payroll
    "payroll.employeeInfo": "Employee info",
    "payroll.agreedSalary": "Agreed",
    "payroll.legalSalary": "Legal",

    //trader
    "trader.fromRegistry": "Insert from Registry",
    "trader.fromSrf": "Insert from Srf",
    "trader.fromTaxSystem": "Insert from TaxSystem",
    "trader.importKadTaxisNet": "Insert KAD from TaxisNet",
    "trader.importKadBranchesTaxisNet": "Insert branches & KAD from TaxisNet",
    "trader.importTraderBoardMember": "Insert trader board member",
    "trader.checkConnection": "Check connection SoftOne",
    "trader.traderInfo": "Trader information",
    "trader.traderKads": "KAD from TaxisNet",
    "trader.traderBranches": "Branches from TaxisNet",
    "trader.boardMember": "Board member",
    "trader.traderRelationships": "Trader legal relationship",
    "trader.traderMemberships": "Trader legal membership",
    "trader.efkaNonSalaried": "Ffka non salaried",
    "trader.traderRating": "Trader rating",
    //error
    "error.error": "Error",
    "error.downloadImageFailed": "Download image failed.",
    "error.uploadSaveForm": "Uploading file requires saving first.",
    "error.validationFormError": "Form fields contain errors.",
    "error.failedToScraping": "failed to scraping.",
    "error.accessDenied": "Access denied.",
    "error.internalServerError": "Internal server error.",
    "error.vatValidation": "Vat number is not valid.",
    "error.adjustedLimit": "The 'Adjusted limit' must be greater than or equal to zero.",
    "error.currentLimit": "The 'Current limit' must be greater than or equal to zero.",
    "error.dailyBreakError": "No break.",
    "error.dailyTotalHoursError": "Exceeded 8 working hours.",
    "error.breakLimitError": "Break limit.",
    "error.breakLimitFormat": "Break {0} is bigger than break limit {1}",
    "error.uniqueVatValidation": "The VAT number is already in use.",
    //menu 
    "menu.catalog": "Catalog",
    "menu.contentManagment": "Content managment",
    "menu.traders": "Traders",
    "menu.customers": "Users",
    "menu.users": "Users",
    "menu.configuration": "Configuration",
    "menu.settings": "Settings",
    "menu.system": "System",
    "menu.environments": "Environments",
    "menu.customerRoles": "Customer roles",
    "menu.security": "Security",
    "menu.securityPermissions": "Security permissions",
    "menu.permissions": "Permissions",
    "menu.customerPermissions": "Users permissions",
    "menu.customerOnline": "Online users",
    "menu.logs": "Logs",
    "menu.messages": "Messages",
    "menu.emailAccounts": "Email accounts",
    "menu.news": "News",
    "menu.blogs": "Blogs",
    "menu.posts": "Posts",
    "menu.forums": "Forums",
    "menu.countries": "Countries",
    "menu.languages": "Languages",
    "menu.currencies": "Currencies",
    "menu.scheduleTasks": "Schedule tasks",
    "menu.queuedEmail": "Mails for delivery",
    "menu.office": "Accounting office",
    "menu.employees": "Employees",
    "menu.educations": "Educations",
    "menu.departments": "Departments",
    "menu.jobTitles": "Job titles",
    "menu.specialties": "Specialties",
    "menu.chambers": "Chambers",
    "menu.workingAreas": "Working areas",
    "menu.accountingWorks": "Accounting works",
    "menu.traderGroup": "Trader group",
    "menu.periodicityItems": "Periodicity items",
    "menu.accounting": "Accounting",
    "menu.tax": "Tax",
    "menu.payroll": "Payroll",
    "menu.apdSubmission": "APD Submissions",
    "menu.fmySubmission": "FMY Submissions",
    "menu.submissions": "Submissions",
    "menu.financially": "Financially",
    "menu.numericalIndicators": "Numerical indicators",
    "menu.payoffLiabilities": "Payoff liabilities",
    "menu.monthlyFinancialBulletin": "Monthly financial bulletin C'category",
    "menu.cashAvailable": "Cash available",
    "menu.documents": "Documents",
    "menu.vatExemptionApproval": "Vat exemption approval",
    "menu.vatExemptionReport": "Vat exemption report",
    "menu.vatExemptionSerial": "Vat exemption serial",
    "menu.vatExemptionDoc": "Vat exemption doc",
    "menu.employeesManager": "Employees manager",
    "menu.vatCalculation": "Vat calculation",
    "menu.aggregateAnalysis": "Aggregate analysis",
    "menu.administratively": "Administratively",
    "menu.crm": "CRM",
    "menu.simpleTaskCategories": "Categories",
    "menu.simpleTaskDepartments": "Departments",
    "menu.simpleTaskNatures": "Nature objects",
    "menu.simpleTaskSectors": "Sectors",
    "menu.simpleTaskManagers": "Task manager",
    "menu.especially": "Especially",
    "menu.projects": "Projects list",
    "menu.viewProjects": "View projects",
    "menu.projectsStatistic": "Projects statistic",
    "menu.workerSchedules": "Schedules",
    "menu.workerScheduleByEmployee": "Schedule submit",
    "menu.workerScheduleByTrader": "Schedule submit",
    "menu.workerScheduleShiftByTrader": "Shifts",
    "menu.workerScheduleLog": "Schedule info",
    "menu.workerSchedulePending": "Schedule pending",
    "menu.intrastat": "Intrastat - Vies",
    "menu.listingF4": "Listing F4",
    "menu.listingF5": "Listing F5",
    "menu.electronicTransmisson": "Electronic transmisson",
    "menu.eSend": "Ε-Send",
    "menu.bankingTransactions": "Banking transactions",
    "menu.piraeusTransactions": "Piraeus transactions",
    "menu.workerCatalog": "Worker catalog",
    "menu.periodic-f2": "FPA Periodiki",
    "menu.assignment": "Tasks",
    "menu.assignmentReason": "Reason Tasks",
    "menu.assignmentTask": "Assignment tasks",
    "menu.assignmentTaskAction": "Assignment actions",
    "menu.assignmentPrototype": "Prototype tasks",
    "menu.assignmentPrototypeAction": "Prototype actions",
    "menu.bookmark": "Βookmark",
    "menu.taxFactor": "Tax factors",
    "menu.workerSickLeave": "Rest sick leave days",
    "menu.workerLeave": "Rest of leave days",
    "menu.workerLeaves": "Leaves",
    "menu.workeLeaveDetail": "Leave work update",
    "menu.obligations": "Obligations",
    "menu.financialObligation": "Financial obligation",
    "menu.emailMessage": "Email manager",
    "menu.activityLog": "Activity logs",
    "menu.activityLogType": "Activity types",
    "menu.customerActivity": "Activity logs",
    "menu.apdContribution": "Apd Contribution",
    "menu.fmyContribution": "Fmy Contribution",
    "menu.payrollStatus": "Payroll status",
    "menu.employeeCostCalculation": "Εmployee Cost Calculation",
    "menu.monthlyBCategoryBulletin": "Monthly financial bulletin B'category",

    "menu.createEmailMessages": "Create email current period",
    "menu.createSelectedEmailMessages": "Create email selected rows",
    "menu.sendFinancialObligationEmails": "Send current period Obligation emails",
    "menu.sendSelectedEmails": "Send selected email",
    "menu.undoTraderDeletion": "Undo deletion",
    "menu.importPayrollIds": "Import HyperM-ID",
    "menu.importKeaoGredentials": "Import gredentials KEAO",
    "menu.importMyDataCredentials": "Import MyData credentials",
    "menu.createEmailInfoFhmPos": "Email info about Fhm-Pos",

    "menu.myData": "MyData",
    "menu.myDataItem": "MyData records",
    "menu.vatTransference": "Vat transference",
    "menu.traderActivityLog": "Trader activity",
    "menu.employeeActivityLog": "Employee activity",

    "menu.traderRating": "Trader rating",
    "menu.traderRatingGravity": "Rating - Gravity",
    "menu.traderRatingCategory": "Rating - Categories",
    "menu.traderRatingByEmployee": "Rating by employees",
    "menu.traderRatingByDepartment": "Rating by departments",
    "menu.traderRatingByTrader": "Rating by traders",
    "menu.payrollCheck": "Payroll check",
    "menu.intertemporal": "Intertemporal",

    //any
    "title.listingF4": "Listing F4",
    "title.listingF5": "Listing F5",

    //any
    "query.1.Cash": "Cash",
    "query.2.Bank": "Bank",
    "query.1.Sales": "Sales",
    "query.2.Purchases": "Purchases",
    "query.3.Expenses": "Expenses",

    //lookups 
    "lookup.levels": [
        { value: 6, label: "6-level (all)" },
        { value: 5, label: "5-level" },
        { value: 4, label: "4-level" },
        { value: 3, label: "3-level" },
        { value: 2, label: "2-level" },
        { value: 1, label: "1-level" }
    ],
    "lookup.f523": [
        { label: "No", value: 0 },
        { label: "Yes", value: 1 },
    ],
    "form.bCategoryMessage": "*The tax scenario is subject to possible other incomes that affect taxation.",

};

// Kendo Angular Component Messages
export const enComponentMessages = {
    // notification
    'kendo.notification.closeTitle': 'xxx',
    //TreeList
    'kendo.treelist.noRecords': 'No records available.',

    // Grid
    'kendo.grid.groupPanelEmpty': 'Drag a column header and drop it here to group by that column',
    'kendo.grid.noRecords': 'No records available.',
    'kendo.grid.pagerFirstPage': 'Go to the first page',
    'kendo.grid.pagerPreviousPage': 'Go to the previous page',
    'kendo.grid.pagerNextPage': 'Go to the next page',
    'kendo.grid.pagerLastPage': 'Go to the last page',
    'kendo.grid.pagerPage': 'Page',
    'kendo.grid.pagerOf': 'of',
    'kendo.grid.pagerItems': 'items',
    'kendo.grid.pagerItemsPerPage': 'items per page',
    'kendo.grid.pagerPageNumberInputTitle': 'Page Number',
    'kendo.grid.filter': 'Filter',
    'kendo.grid.filterEqOperator': 'Is equal to',
    'kendo.grid.filterNotEqOperator': 'Is not equal to',
    'kendo.grid.filterIsNullOperator': 'Is null',
    'kendo.grid.filterIsNotNullOperator': 'Is not null',
    'kendo.grid.filterIsEmptyOperator': 'Is empty',
    'kendo.grid.filterIsNotEmptyOperator': 'Is not empty',
    'kendo.grid.filterStartsWithOperator': 'Starts with',
    'kendo.grid.filterContainsOperator': 'Contains',
    'kendo.grid.filterNotContainsOperator': 'Does not contain',
    'kendo.grid.filterEndsWithOperator': 'Ends with',
    'kendo.grid.filterGteOperator': 'Is greater than or equal to',
    'kendo.grid.filterGtOperator': 'Is greater than',
    'kendo.grid.filterLteOperator': 'Is less than or equal to',
    'kendo.grid.filterLtOperator': 'Is less than',
    'kendo.grid.filterIsTrue': 'is true',
    'kendo.grid.filterIsFalse': 'is false',
    'kendo.grid.filterBooleanAll': '(All)',
    'kendo.grid.filterAfterOrEqualOperator': 'Is after or equal to',
    'kendo.grid.filterAfterOperator': 'Is after',
    'kendo.grid.filterBeforeOperator': 'Is before',
    'kendo.grid.filterBeforeOrEqualOperator': 'Is before or equal to',
    'kendo.grid.filterFilterButton': 'Filter',
    'kendo.grid.filterClearButton': 'Clear',
    'kendo.grid.filterAndLogic': 'And',
    'kendo.grid.filterOrLogic': 'Or',
    'kendo.grid.filterDateToggle': 'Toggle calendar',
    'kendo.grid.filterDateToday': 'Today',
    'kendo.grid.filterNumericDecrement': 'Decrease value',
    'kendo.grid.filterNumericIncrement': 'Increase value',
    'kendo.grid.loading': 'Loading',
    'kendo.grid.sort': 'Sort',
    'kendo.grid.columnMenu': 'Column Menu',
    'kendo.grid.columns': 'Columns',
    'kendo.grid.lock': 'Lock',
    'kendo.grid.unlock': 'Unlock',
    'kendo.grid.stick': 'Stick',
    'kendo.grid.unstick': 'Unstick',
    'kendo.grid.setColumnPosition': 'Set Column Position',
    'kendo.grid.sortAscending': 'Sort Ascending',
    'kendo.grid.sortDescending': 'Sort Descending',
    'kendo.grid.columnsApply': 'Apply',
    'kendo.grid.columnsReset': 'Reset',
    'kendo.grid.sortable': 'Sortable',
    'kendo.grid.sortedAscending': 'Sorted ascending',
    'kendo.grid.sortedDescending': 'Sorted descending',
    'kendo.grid.sortedDefault': 'Not sorted',

    // DropDowns
    'kendo.autocomplete.noDataText': 'No data found',
    'kendo.autocomplete.clearTitle': 'Clear',
    'kendo.combobox.noDataText': 'No data found',
    'kendo.combobox.clearTitle': 'Clear',
    'kendo.dropdownlist.noDataText': 'No data found',
    'kendo.dropdownlist.clearTitle': 'Clear',

    //multiselecttree
    "kendo.multiselecttree.noDataText": "No data found",
    "kendo.multiselecttree.clearTitle": "Clear",
    "kendo.multiselecttree.checkAllText": "Select all",
    "kendo.multiselecttree.selectButtonText": "Select",
    "kendo.multiselecttree.filterInputLabel": "Filter",
    "kendo.multiselecttree.popupLabel": "Options list",
    "kendo.multiselecttree.applyButton": "Apply",
    "kendo.multiselecttree.cancelButton": "Cancel",

    // Calendar
    'kendo.calendar.today': 'TODAY',

    // TextBox
    'kendo.textbox.clearTitle': 'Clear',

    // DatePicker
    'kendo.datepicker.today': 'TODAY',
    'kendo.datepicker.toggle': 'Toggle calendar',

    // DateInput
    'kendo.dateinput.increment': 'Increase value',
    'kendo.dateinput.decrement': 'Decrease value',

    // NumericTextBox
    'kendo.numerictextbox.increment': 'Increase value',
    'kendo.numerictextbox.decrement': 'Decrease value',

    // DateTimePicker
    'kendo.datetimepicker.dateTab': 'Date',
    'kendo.datetimepicker.dateTabLabel': 'Date tab',
    'kendo.datetimepicker.timeTab': 'Time',
    'kendo.datetimepicker.timeTabLabel': 'Time tab',
    'kendo.datetimepicker.toggle': 'Toggle popup',
    'kendo.datetimepicker.accept': 'Set',
    'kendo.datetimepicker.acceptLabel': 'Set',
    'kendo.datetimepicker.cancel': 'Cancel',
    'kendo.datetimepicker.cancelLabel': 'Cancel',
    'kendo.datetimepicker.now': 'NOW',
    'kendo.datetimepicker.nowLabel': 'Select now',
    'kendo.datetimepicker.today': 'TODAY',
    'kendo.datetimepicker.prevButtonTitle': 'Navigate to previous view',
    'kendo.datetimepicker.nextButtonTitle': 'Navigate to next view',

    // TimePicker
    'kendo.timepicker.accept': 'Set',
    'kendo.timepicker.acceptLabel': 'Set',
    'kendo.timepicker.cancel': 'Cancel',
    'kendo.timepicker.cancelLabel': 'Cancel',
    'kendo.timepicker.now': 'Now',
    'kendo.timepicker.nowLabel': 'Now',
    'kendo.timepicker.toggle': 'Toggle time list',

    // FileSelect
    'kendo.fileselect.cancel': 'Cancel',
    'kendo.fileselect.clearSelectedFiles': 'Clear',
    'kendo.fileselect.dropFilesHere': 'Drop files here to upload',
    'kendo.fileselect.externalDropFilesHere': 'Drag and drop files here to upload',
    'kendo.fileselect.filesBatchStatus': 'files',
    'kendo.fileselect.filesBatchStatusFailed': 'files failed to upload.',
    'kendo.fileselect.filesBatchStatusUploaded': 'files successfully uploaded.',
    'kendo.fileselect.fileStatusFailed': 'File failed to upload.',
    'kendo.fileselect.fileStatusUploaded': 'File successfully uploaded.',
    'kendo.fileselect.headerStatusPaused': 'Paused',
    'kendo.fileselect.headerStatusUploaded': 'Done',
    'kendo.fileselect.headerStatusUploading': 'Uploading...',
    'kendo.fileselect.invalidFileExtension': 'File type not allowed.',
    'kendo.fileselect.invalidMaxFileSize': 'File size too large.',
    'kendo.fileselect.invalidMinFileSize': 'File size too small.',
    'kendo.fileselect.remove': 'Remove',
    'kendo.fileselect.retry': 'Retry',
    'kendo.fileselect.select': 'Select files...',
    'kendo.fileselect.uploadSelectedFiles': 'Upload files',

    // Upload
    'kendo.upload.cancel': 'Cancel',
    'kendo.upload.clearSelectedFiles': 'Clear',
    'kendo.upload.dropFilesHere': 'Drop files here to upload',
    'kendo.upload.externalDropFilesHere': 'Drag and drop files here to upload',
    'kendo.upload.filesBatchStatus': 'files',
    'kendo.upload.filesBatchStatusFailed': 'files failed to upload.',
    'kendo.upload.filesBatchStatusUploaded': 'files successfully uploaded.',
    'kendo.upload.fileStatusFailed': 'File failed to upload.',
    'kendo.upload.fileStatusUploaded': 'File successfully uploaded.',
    'kendo.upload.headerStatusPaused': 'Paused',
    'kendo.upload.headerStatusUploaded': 'Done',
    'kendo.upload.headerStatusUploading': 'Uploading...',
    'kendo.upload.invalidFileExtension': 'File type not allowed.',
    'kendo.upload.invalidMaxFileSize': 'File size too large.',
    'kendo.upload.invalidMinFileSize': 'File size too small.',
    'kendo.upload.pause': 'Pause',
    'kendo.upload.remove': 'Remove',
    'kendo.upload.resume': 'Resume',
    'kendo.upload.retry': 'Retry',
    'kendo.upload.select': 'Select files...',
    'kendo.upload.uploadSelectedFiles': 'Upload files',

    // Scheduler
    "kendo.scheduler.agendaViewTitle": "Agenda",
    "kendo.scheduler.allDay": "all day",
    "kendo.scheduler.allEvents": "All events",
    "kendo.scheduler.calendarToday": "TODAY",
    "kendo.scheduler.cancel": "Cancel",
    "kendo.scheduler.save": "Save",
    "kendo.scheduler.editorEventTitle": "Title",
    "kendo.scheduler.editorEventStart": "Start",
    "kendo.scheduler.editorEventStartTimeZone": "Start Time Zone",
    "kendo.scheduler.editorEventEnd": "End",
    "kendo.scheduler.editorEventEndTimeZone": "End Time Zone",
    "kendo.scheduler.dateHeader": "Date",
    "kendo.scheduler.dayViewTitle": "Day",
    "kendo.scheduler.deleteConfirmation": "Are you sure you want to delete this event?",
    "kendo.scheduler.deleteDialogTitle": "Delete Event",
    "kendo.scheduler.deleteOccurrence": "Delete current occurrence",
    "kendo.scheduler.deleteRecurringConfirmation": "Do you want to delete only this event occurrence or the whole series?",
    "kendo.scheduler.deleteRecurringDialogTitle": "Delete Recurring Item",
    "kendo.scheduler.deleteSeries": "Delete the series",
    "kendo.scheduler.deleteTitle": "Delete",
    "kendo.scheduler.destroy": "Delete",
    "kendo.scheduler.editOccurrence": "Edit current occurrence",
    "kendo.scheduler.editorEventAllDay": "All Day Event",
    "kendo.scheduler.editorEventDescription": "Description",
    "kendo.scheduler.editorEventSeparateTimeZones": "End in different Time Zone",
    "kendo.scheduler.editorEventTimeZone": "Specify Time Zone",
    "kendo.scheduler.editorTitle": "Event",
    "kendo.scheduler.editRecurringConfirmation": "Do you want to edit only this event occurrence or the whole series?",
    "kendo.scheduler.editRecurringDialogTitle": "Edit Recurring Item",
    "kendo.scheduler.editSeries": "Edit the series",
    "kendo.scheduler.eventHeader": "Event",
    "kendo.scheduler.monthViewTitle": "Month",
    "kendo.scheduler.multiDayViewTitle": "Multi-Day",
    "kendo.scheduler.nextTitle": "Next",
    "kendo.scheduler.previousTitle": "Previous",
    "kendo.scheduler.recurrenceEditorDailyInterval": "day(s)",
    "kendo.scheduler.recurrenceEditorDailyRepeatEvery": "Repeat every",
    "kendo.scheduler.recurrenceEditorEndAfter": "After",
    "kendo.scheduler.recurrenceEditorEndLabel": "End",
    "kendo.scheduler.recurrenceEditorEndNever": "Never",
    "kendo.scheduler.recurrenceEditorEndOccurrence": "occurrence(s)",
    "kendo.scheduler.recurrenceEditorEndOn": "On",
    "kendo.scheduler.recurrenceEditorFrequenciesDaily": "Daily",
    "kendo.scheduler.recurrenceEditorFrequenciesMonthly": "Monthly",
    "kendo.scheduler.recurrenceEditorFrequenciesNever": "Never",
    "kendo.scheduler.recurrenceEditorFrequenciesWeekly": "Weekly",
    "kendo.scheduler.recurrenceEditorFrequenciesYearly": "Yearly",
    "kendo.scheduler.recurrenceEditorMonthlyDay": "Day",
    "kendo.scheduler.recurrenceEditorMonthlyInterval": "month(s)",
    "kendo.scheduler.recurrenceEditorMonthlyRepeatEvery": "Repeat every",
    "kendo.scheduler.recurrenceEditorMonthlyRepeatOn": "Repeat on",
    "kendo.scheduler.recurrenceEditorOffsetPositionsFirst": "First",
    "kendo.scheduler.recurrenceEditorOffsetPositionsFourth": "Fourth",
    "kendo.scheduler.recurrenceEditorOffsetPositionsLast": "Last",
    "kendo.scheduler.recurrenceEditorOffsetPositionsSecond": "Second",
    "kendo.scheduler.recurrenceEditorOffsetPositionsThird": "Third",
    "kendo.scheduler.recurrenceEditorRepeat": "Repeat",
    "kendo.scheduler.recurrenceEditorWeekdaysDay": "Day",
    "kendo.scheduler.recurrenceEditorWeekdaysWeekday": "Weekday",
    "kendo.scheduler.recurrenceEditorWeekdaysWeekendday": "Weekend Day",
    "kendo.scheduler.recurrenceEditorWeeklyInterval": "week(s)",
    "kendo.scheduler.recurrenceEditorWeeklyRepeatEvery": "Repeat every",
    "kendo.scheduler.recurrenceEditorWeeklyRepeatOn": "Repeat on",
    "kendo.scheduler.recurrenceEditorYearlyInterval": "year(s)",
    "kendo.scheduler.recurrenceEditorYearlyOf": "of",
    "kendo.scheduler.recurrenceEditorYearlyRepeatEvery": "Repeat every",
    "kendo.scheduler.recurrenceEditorYearlyRepeatOn": "Repeat on",
    "kendo.scheduler.showFullDay": "Show full day",
    "kendo.scheduler.showWorkDay": "Show business hours",
    "kendo.scheduler.timeHeader": "Time",
    "kendo.scheduler.timelineMonthViewTitle": "Timeline Month",
    "kendo.scheduler.timelineViewTitle": "Timeline",
    "kendo.scheduler.timelineWeekViewTitle": "Timeline Week",
    "kendo.scheduler.today": "Today",
    "kendo.scheduler.weekViewTitle": "Week",
    "kendo.scheduler.workWeekViewTitle": "Work Week",

    // RecurrenceEditor
    "kendo.recurrenceeditor.dailyInterval": "day(s)",
    "kendo.recurrenceeditor.dailyRepeatEvery": "Repeat every",
    "kendo.recurrenceeditor.endAfter": "After",
    "kendo.recurrenceeditor.endLabel": "End",
    "kendo.recurrenceeditor.endNever": "Never",
    "kendo.recurrenceeditor.endOccurrence": "occurrence(s)",
    "kendo.recurrenceeditor.endOn": "On",
    "kendo.recurrenceeditor.frequenciesDaily": "Daily",
    "kendo.recurrenceeditor.frequenciesMonthly": "Monthly",
    "kendo.recurrenceeditor.frequenciesNever": "Never",
    "kendo.recurrenceeditor.frequenciesWeekly": "Weekly",
    "kendo.recurrenceeditor.frequenciesYearly": "Yearly",
    "kendo.recurrenceeditor.monthlyDay": "Day",
    "kendo.recurrenceeditor.monthlyInterval": "month(s)",
    "kendo.recurrenceeditor.monthlyRepeatEvery": "Repeat every",
    "kendo.recurrenceeditor.monthlyRepeatOn": "Repeat on",
    "kendo.recurrenceeditor.offsetPositionsFourth": "Fourth",
    "kendo.recurrenceeditor.offsetPositionsLast": "Last",
    "kendo.recurrenceeditor.offsetPositionsSecond": "Second",
    "kendo.recurrenceeditor.offsetPositionsThird": "Third",
    "kendo.recurrenceeditor.repeat": "Repeat",
    "kendo.recurrenceeditor.weekdaysDay": "Day",
    "kendo.recurrenceeditor.weekdaysWeekday": "Weekday",
    "kendo.recurrenceeditor.weekdaysWeekendday": "Weekend Day",
    "kendo.recurrenceeditor.weeklyInterval": "week(s)",
    "kendo.recurrenceeditor.weeklyRepeatEvery": "Repeat every",
    "kendo.recurrenceeditor.weeklyRepeatOn": "Repeat on",
    "kendo.recurrenceeditor.yearlyInterval": "year(s)",
    "kendo.recurrenceeditor.yearlyOf": "of",
    "kendo.recurrenceeditor.yearlyRepeatEvery": "Repeat every",
    "kendo.recurrenceeditor.yearlyRepeatOn": "Repeat on",
};
